<template>
  <Page :hasBg="false" :loading="loading">
    <!-- 导航栏 -->
    <NavBar title="提现记录"> </NavBar>

    <!-- 列表 -->
    <div class="bg-white" :class="list && list.length > 7 ? '' : 'h-full'">
      <List
        class="px-16 list"
        v-model="loading"
        :error.sync="error"
        error-text="暂无提现记录"
        :finished="finished"
        :finished-text="inited && !list.length ? '' : `没有更多了`"
        @load="getListData"
      >
        <ul class="record-list">
          <li class="record-list-card d-flex" v-for="item in list" :key="item.id">
            <Icon class="icon" :name="'out'"></Icon>
            <div class="d-flex justify-space-between flex-grow-1">
              <div class="ml-8 center text-14">
                <div class="d-flex align-center">
                  <p>{{ '提现' }}</p>
                  <p v-if="item.state === 2 && item.bankCardNo && item.bankName" class="ml-8">
                    {{ item.bankName }} ({{ item.bankCardNo.slice(-4) }})
                  </p>
                </div>
                <p class="mt-8 text_muted">
                  {{ formatToDateTime(item.createTime, 'MM月dd日 hh:mm') }}
                </p>
              </div>
              <div class="text-right right text-14">
                <p>-{{ (item.amount / 100).toFixed(2) }}</p>
                <p
                  class="mt-8 font-14"
                  :class="{
                    'color-warning': item.state === 1,
                    'color-success': item.state === 2,
                    'color-danger': item.state === 3
                  }"
                >
                  {{ item.stateText }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </List>

      <!-- 缺省页 -->
      <!-- <Empty v-if="!list.length" imgWidth="0.84rem" imgHeight="0.78rem" :image="emptyImg" description="暂无提现记录" /> -->
      <div v-if="inited && !list.length" class="flex flex-col items-center justify-center h-full">
        <div class="flex flex-col items-center p-32">
          <img
            class="w-134 h-134"
            src="https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/siji_xiaochegnxu/chatu_wuzijinmingxi%403x.png"
          />
          <span class="text-muted text-16">无提现记录</span>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, ref } from '@vue/composition-api';
import NavBar from '@/components/NavBar/index';
import Empty from '@/components/Empty/index';
import { Button, Popover, List } from 'vant';
import useFormatMoney from '@/hook/useFormatMoney';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { apiSearchTradeWithdrawRecordPage } from '@/api/balance';
import { useUserStore } from '@/store/user';

export default defineComponent({
  components: {
    NavBar,
    Button,
    Popover,
    List,
    Empty
  },
  setup(_, ctx) {
    // 判断是不是第一次加载
    const inited = ref(false);

    // 缺省页图片
    const emptyImg = require('@/assets/ydkb.png');

    // 选中的状态
    const selected = ref({ text: '全部', value: 0 });
    // 状态选择栏
    const showPopover = ref(false);
    const actions = ref([
      { text: '全部', value: 0 },
      { text: '收入', value: 1 },
      { text: '支出', value: 2 }
    ]);
    // 列表
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const error = ref(false);
    const pageNum = ref(1);
    const userStore = useUserStore();

    // 获取列表数据
    const getListData = async () => {
      // 获取运单列表接口
      // const [e, res] = await ctx.root.$await(
      const { data, code } = await apiSearchTradeWithdrawRecordPage({
        pageNum: pageNum.value,
        pageSize: 10,
        driverIdCard: userStore?.idCard
      });
      // );
      // console.log(res);

      if (code !== '00000') {
        error.value = true;
      } else {
        const records = data?.records || [];
        if (records.length >= data.total) {
          finished.value = true;
        } else {
          pageNum.value++;
        }
        list.value = list.value.concat(records);
      }

      // if (e) {
      //   // 如果请求失败，显示失败文案
      //   setTimeout(() => {
      //     error.value = true;
      //   }, 1000);
      // } else {
      //   inited.value = true;

      //   const data = res.data;
      //   if (data.records.length) {
      //     list.value = list.value.concat(data.records);
      //     pageNum.value++;
      //   } else {
      //     // 如果没有数据了，显示完成文案
      //     setTimeout(() => {
      //       finished.value = true;
      //     }, 1000);
      //   }
      // }
      setTimeout(() => {
        loading.value = false;
      }, 300);
    };

    const handleSelect = action => {
      showPopover.value = false;
      selected.value = action;
    };

    return {
      useFormatMoney,
      showPopover,
      actions,
      handleSelect,
      selected,
      list,
      loading,
      error,
      finished,
      pageNum,
      getListData,
      emptyImg,
      formatToDateTime,
      inited
    };
  }
});
</script>
<style lang="less" scoped>
.record-list {
  &-card {
    margin-top: 0.1rem;
    padding: 0.12rem 0;
    border-bottom: 1px solid #f5f5f5;
    &:first-child {
      margin-top: 0;
    }
    &:nth-last-child(1) {
      border: none;
    }
  }
}
</style>
